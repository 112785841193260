.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-bg-color {
  background-color: rgb(34, 93, 153) !important;
}

.App-bg-color-2 {
  background-color: rgb(245, 141, 93) !important;
}

.App-color {
  color: rgb(34, 93, 153) !important;
}

.App-color-2 { color: rgb(245, 141, 93) !important; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.w-100 {
  width: 100%;
}

.m-t-10 {
  margin-top: 10px !important;
}

.ececec {
  color: #ececec;
}

.gray {
  color: gray;
}

.white {
  color: white;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://upjao.ai/wp-content/themes/UpjaoTheme/1x/countryside-2326787.jpg");
  background-size: cover;
}

.text-line {
  display: flex;
  flex-direction: row;
}

.text-line:before,
.text-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid gray;
  margin: auto;
}

.cursor {
  cursor: pointer;
}

.button-padding {
  padding: 4px 16px !important;
}

.icon-position {
  position: 'relative';
  top: '4px';
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidebar {
  height: 100%;
  width: 250px;
  background-color: #2f4050;
}

.sidebarList {
  height: 100vh;
  padding: 0;
  width: 100%;
}

.sidebarList .row {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  list-style-type: none;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.sidebarList .row :hover {
  cursor: pointer !important;
  background-color: #293846 !important;
}

.row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.row #title {
  flex: 70%;
  display: grid;
  place-items: start;
}

.table-background {
  background-color: aliceblue !important;
}

.no-column-selection .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.table-input {
  display: flex;
  justify-content: flex-start;
  gap: 11px
}

.input-group {
  display: flex;
  align-items: center;
}

.m-20 {
  margin: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.no-outline {
  outline: none !important;
}

.user-container {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}

.scan-content-container {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}

.scan-container {
  width: 100%;
  margin: 20px;
  height: 100vh;
}

.scan-content-container::-webkit-scrollbar {
  width: 15px;
  background-color: transparent;
}

.scan-content-container::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.user-content {
  flex-grow: 1;
  padding: 12px;
  margin-left: 10px;
  margin-top: 85px;
}

.scan-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.centered-cell {
  text-align: center;
}

.back-arrow {
  padding: 10px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
}

.tab-content {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.justify-around {
  justify-content: space-around;
}

.display-block {
  display: block !important;
}

.card-container {
  display: flex;
  flex-direction: column;
}

.key-value-pair {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.key {
  font-weight: bold;
  color: #b34d00;
  white-space: nowrap;
}

.value {
  color: black;
  word-break: break-word;
  align-self: flex-start;
  margin-left: 8px;
}

.opacity-9 {
  opacity: 0.9;
}

.css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.qr-code-wrapper {
  padding: 0 60px 60px;
}

::-webkit-scrollbar {
  width: 0.1px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.icon-container {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  color: red;
  font-size: 24px;
}

.image-class {
  align-items: center;
  display: 'flex';
  gap: 10px;
}